const api ={
    ABOUT_URL : "https://8p0y0xlz.api.sanity.io/v2021-10-21/data/query/production?query=*%5B_type%20%3D%3D%20'author'%5D%20%7B%0A%20%20name%2C%20aboutMe%2C%20aboutSite%0A%7D",
    PROJECT_URL : "https://8p0y0xlz.api.sanity.io/v2021-10-21/data/query/production?query=*%5B_type%20%3D%3D%20'project'%5D%20%0A%20%20%7B_id%2C%20name%2C%20%0A%20%20image%7B%0A%20%20%20%20%20%20%20%20asset-%3E%7B%0A%20%20%20%20%20%20%20%20%20%20url%0A%20%20%20%20%20%20%20%20%7D%2C%0A%20%20%20%20%20%20%7D%2C%0A%20%20tasks%5B%5D%0A%20%20%20%20%20%20%20%20%7Bchildren%5B%5D%7Btext%7D%7D%2C%20%0A%20%20tech_stack%2C%20github%2C%20details%7D",
    SKILL_URL : "https://8p0y0xlz.api.sanity.io/v2021-10-21/data/query/production?query=*%5B_type%20%3D%3D%20'skill'%5D%20%7Bskils%7D",
    EXPERIENCE_URL : "https://8p0y0xlz.api.sanity.io/v2021-10-21/data/query/production?query=*%5B_type%20%3D%3D%20'experience'%5D%20%7B_id%2C%20position%2C%0A%20%20tasks%2C%20date_range%2C%20description%2C%20company%7D",
    EMAIL_SERVICE_ID : "service_hracwj8",
    EMAIL_TEMPLATE_ID : "template_czme2hk",
    EMAIL_PUBLIC_KEY : "Gh-p8u62jJ_0v5FfU",
}

export default api;